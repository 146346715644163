<template>
  <router-view />
</template>

<style>
@font-face {
  font-family: "THSarabunNew";
  src: local("THSarabunNew"), url(./fonts/THSarabunNew.ttf) format("truetype");
}

/* #app {
  font-family: "THSarabunNew";
  font-size: 20px;
} */
</style>
