import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://api-console.ppwtech.com",
  headers: {
    "Content-Type": "application/json",
  },
});

export default {
  async logIn(operator_token) {
    return await apiClient
      .post("/oparator/sign-in", {
        operator_token: operator_token,
      })
      .then((response) => {
        console.log("User profile", response.data);
        localStorage.setItem("operator.profile", JSON.stringify(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
  },
  async getMe() {
    return localStorage.getItem("operator.profile");
  },
};
