import { createRouter, createWebHistory } from "vue-router";
import signin from "@/service/signin";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/product/PeopleView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/product/patrol",
    name: "PatrolView",
    component: () => import("../views/product/PatrolView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/vms/people",
    name: "PeopleView",
    component: () => import("../views/product/PeopleView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("../views/DemoView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  var auth = await signin.getMe();

  if (requiresAuth && !auth) {
    // ถ้าเส้นทางต้องการสิทธิ์การเข้าถึงและไม่มี JWT
    next("/login"); // กลับไปยังหน้าเข้าสู่ระบบ
  } else if (to.name === "login" && auth) {
    // ถ้าผู้ใช้เข้าสู่ระบบแล้ว
    next("/vms/people");
  } else {
    next(); // ดำเนินการต่อไป
  }
});

export default router;
